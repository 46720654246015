@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.intro {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  background-image: url('../../assets/images/ui/intro-bg.jpeg');
  background-position: center;
  background-size: cover;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    background-image: url('../../assets/images/ui/intro-bg-mobile.jpeg');
  }
}

.carousel {
  width: 82vh;
  max-width: 100%;

  @media screen and (max-width: $breakpoint-mobile-landscape-md) {
    width: 58vh;
  }
}

.slide {
  outline: none;
}

.play-btn {
  width: 200px;
  height: 50px;
}

.intro-bottom {
  display: flex;
  justify-content: space-around;
  padding: 20px;

  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    min-width: 50%;
  }
}

.label {
  margin-left: 3px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
}

//demo
.demo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding: 4vh 0;
  }

  @media screen and (max-width: $breakpoint-mobile-landscape-md) {
    padding: 1vh 0;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.playBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;

  img {
    display: block;
    width: 200px;
    height: 50px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
  img:before {
    border-radius: 20px;
    bottom: 0;
    box-shadow: inset 0 0 10px 10px #000;
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  img:active {
    -webkit-filter: drop-shadow(3px 3px 3px rgba(34, 34, 34, 0.705));
    filter: drop-shadow(3px 3px 3px rgba(34, 34, 34, 0.705));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-transform: scale(1, 0.95);
    -webkit-transform: scale(1, 0.95);
    transform: scale(1, 0.95);
    will-change: transform;
  }
}

.title {
  margin-bottom: 4vh;
  font-size: 5vh;
  line-height: 1;
  color: #fff;
  letter-spacing: 3.25px;
  text-align: center;
  font-weight: 700;
  text-shadow: 0px 0px 7px #a20e02, 0px 0px 7px #a20e02, 0px 0px 7px #a20e02, 0px 0px 7px #a20e02, 0px 0px 7px #a20e02,
    0px 0px 7px #a20e02, 0px 0px 7px #a20e02, 0px 0px 7px #a20e02;

  span {
    color: #fcef24;
  }

  @media screen and (max-width: $breakpoint-mobile-landscape-max) and (orientation: portrait) {
    font-size: 5vw;
  }
}

.toggle {
  min-width: 40px;
}
