.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}

@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#ff4729, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(#ff4729, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#ff4729, 0);
  }
}

@keyframes pulse-warning {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#f2c94c, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(#f2c94c, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#f2c94c, 0);
  }
}
