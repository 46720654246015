.clock {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 15px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1px 5px;
  border-radius: 5px;
}
