.input-group {
  display: flex;
  padding: inherit;

  input {
    margin: 0 2px;
  }
}

.input {
  caret-color: transparent;
}
