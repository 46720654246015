@import '../../assets/styles/variables';

.layout {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  user-select: none;
}
