@import './variables.scss';
@import './button.scss';
@import './forms.scss';
@import './popup.scss';
@import './animations.scss';

@font-face {
  font-family: 'NotoSans-SemiCondensedBold';
  src: local('NotoSans-SemiCondensedBold'), url(../fonts/NotoSans_SemiCondensed-Bold.woff) format('woff'),
    url(../fonts/NotoSans_SemiCondensed-Bold.ttf) format('truetype');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'NotoSans-SemiCondensedBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
