@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.autoplay-settings {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__item {
    margin: 5px 0;
    pointer-events: all;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.autoplay-settings::-webkit-scrollbar {
  display: none;
}

.label {
  font-size: 13px;
  font-weight: bold;
  margin: 3px 0;
  color: #fff;
}

.input {
  padding: 1px 2px;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 35px;
  margin: 2px;
  border-radius: 3px;
  border: none;
  font-weight: bold;
  color: #000;
  box-shadow: -1px -1px 0 1px #dcb432 inset;
  background-color: #fff;
  transition: all 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
  outline: none;

  &:disabled {
    text-shadow: none;
    cursor: default;
    opacity: 0.5;
  }

  @media screen and (min-width: $breakpoint-laptop-landscape) {
    &:hover:not(:disabled) {
      background-color: #dcb432;
      box-shadow: 1px 1px 0 1px #000 inset;
    }
  }

  &.checked:not(:disabled),
  &:checked:not(:disabled) {
    background-color: #dcb432;
    box-shadow: 1px 1px 0 1px #000 inset;
  }
}
.playBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  img {
    display: block;
    width: 150px;
    height: 39px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}
